@use '../base/functions' as breakpoints;

button {
    opacity: 1;
    cursor: pointer;
    border: none;

    &:hover {
        opacity: 0.7;
        transition: opacity 250ms ease-in;
    }
}

.btn-lg,
.btn-sm,
.btn-md {
    border-radius: 20px;
}

.btn-lg {
    @include breakpoints.sm {
        height: 35%;
        width: 50%;
    }
    @include breakpoints.lg {
        width: 20%;
        height: 40%;
    }
}

.btn-sm {
    height: 10%;
    width: 45%;
}

.btn-md {
    height: 70%;
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
}

.choice-btn {
    width: 99%;
    height: 99%;
    letter-spacing: 1.1px;

}
