* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
}


#root {
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    
}



.btnMd {
    height: 15%;
}